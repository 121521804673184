import React, { useState } from "react";
import "./App.css";

function App() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    username: "",
    reason: "",
    confirm: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.confirm) {
      console.log("Form Data Submitted:", formData);
      setIsSubmitted(true);
    } else {
      alert("Please confirm that you understand the action is permanent.");
    }
  };

  return (
    <div className="container">
      {isSubmitted ? (
        <div className="thank-you">
          <h2>Request Submitted</h2>
          <p>Thank you for submitting your request. We will process it shortly.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="deletion-form">
          <h1>Request to Delete Your Account</h1>
          <p>
            We value your privacy. Deleting your account will permanently remove all your data from our servers.
          </p>
          <ul>
            <li>This action is irreversible.</li>
            <li>Your data, including profile and activity history, will be deleted.</li>
            <li>Please ensure there are no active subscriptions before proceeding.</li>
          </ul>
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email address"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username (if applicable)</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Enter your username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="reason">Reason for Deletion</label>
            <textarea
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              placeholder="Please explain why you want to delete your account"
              required
            ></textarea>
          </div>
          <div className="form-group checkbox">
            <input
              type="checkbox"
              id="confirm"
              name="confirm"
              checked={formData.confirm}
              onChange={handleChange}
            />
            <label htmlFor="confirm">
              I understand that this action is permanent and cannot be undone.
            </label>
          </div>
          <button type="submit" className="submit-btn">
            Request Account Deletion
          </button>
        </form>
      )}
    </div>
  );
}

export default App;
